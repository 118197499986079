@import '../base/variables';

main {
  .intro {
    max-width: 740px;
    margin: 30px auto 50px auto;
    text-align: center;
    font-size: 1.5em;
    line-height: 1.3em;

    span {
      font-size: 1.05em;
      font-family: $accentFont;
    }

    p {
      &:first-of-type {
        margin-top: 0.8em;
      }
    }

    .avatar {
      width: 100px;
      height: 100px;
      margin: 0 auto;
      border-radius: 50%;
      background: $lightGrey;
    }
  }

  .projects {
    position: relative;
    max-width: 800px;
    margin: 0 auto;

    .timeline-wrapper {
      position: -webkit-sticky;
      position: sticky;
      z-index: 1000;
      top: 0;
      width: 100%;
      margin: 0 auto;
      background: $white;

      -webkit-box-shadow: 0px 2px 5px 2px rgba($white, 1);
      -moz-box-shadow: 0px 2px 5px 2px rgba($white, 1);
      box-shadow: 0px 2px 5px 2px rgba($white, 1);

      .timeline {
        height: 60px;
        width: 100%;
        max-width: 800px;

        hr.main {
          position: absolute;
          top: 50%;
          width: 100%;
          margin: 0;
          border: none;
          border-top: $border;
        }
        hr.year {
          width: 1px;
          height: 15px;
          position: absolute;
          top: 50%;
          margin: 0;
          transform: translateY(-7px);
          border: none;
          border-left: $border;
        }

        .label {
          position: absolute;
          top: calc(50% + 11px);
          margin-left: 1px;
          transform: translateX(-50%);
          font-size: 0.85em;
          opacity: 0.8;
        }

        .point {
          position: absolute;
          top: 50%;
          width: 7px;
          height: 7px;
          border-radius: 50%;
          border: $border;
          background: lighten($accentColor, 20%);
          transform: translateX(-3px) translateY(-3px);
        }
      }
    }

    .list {
      width: calc(100% - 10px);
      margin-left: 5px;

      .year-label {
        display: block;
        width: 100%;
        margin: 25px 0;
        position: relative;
        text-align: center;
        font-size: 1.8em;
        font-family: $accentFont;
      }

      .project {
        width: 100%;
        padding-bottom: 30px;

        a {
          border: none;
        }

        img {
          width: 100%;
          object-fit: cover;
          object-position: left;
          border-radius: 4px;
          border: 1px solid $lightGrey;
          filter: drop-shadow(0 2px 2px $lightGrey);
        }

        .caption {
          padding-bottom: 0.5em;
          line-height: 1.4em;

          .small {
            color: $grey;
            font-size: 0.8em;

            a {
              color: $grey !important;
              border-bottom: 1px solid $lightGrey;

              &:hover {
                border-bottom-color: $grey;
              }
            }
          }
        }

        &.disabled {
          a {
            pointer-events: none;
            cursor: default;
            color: $textColor !important;
          }
        }

        &:hover {
          .caption a {
            color: darken($accentColor, 10%);
          }
        }
      }
    }
  }

  @media (min-width: $sm) {
    .intro {
      margin: 40px auto 60px auto;
      font-size: 2em;

      .avatar {
        width: 150px;
        height: 150px;
      }
    }

    .projects {
      .timeline-wrapper {
        .timeline {
          .point {
            width: 11px;
            height: 11px;
            transform: translateX(-5px) translateY(-5px);
          }
        }
      }

      .list {
        .year-label {
          margin: 30px 0;
        }

        .project {
          width: 80%;
          margin: 0 15px;
          padding-bottom: 45px;

          .caption {
            font-size: 1.2em;
          }

          &:nth-of-type(2n) {
            margin-left: 20%;
          }
        }
      }
    }
  }
}
