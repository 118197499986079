@import '../base/variables';

h2 {
  margin-bottom: 0.8em;

  span {
    display: inline-block;
    margin-left: 2em;
    margin-right: -1em;
    font-size: 0.7em;
    border-bottom: 1px solid $grey;
    cursor: pointer;

    &:hover {
      border-bottom-color: $darkGrey;
    }

    &.active {
      cursor: default;
      pointer-events: none;
      border-bottom-color: rgba($grey, 0.01);
    }
  }
}

.types-legend {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.6em;

  div {
    display: flex;
    align-items: center;
    margin-right: 20px;

    span {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 2px;
      margin-right: 4px;
    }
  }
}

.maps {
  display: flex;
  flex-wrap: wrap;

  .map {
    position: relative;
    max-width: 100%;
    width: 360px;
    height: 240px;
    margin: 0 10px 10px 0;
    border: 1px solid $lightGrey;

    canvas {
      background: url('/static/images/washington-map.jpg');
    }
  }
}

svg.counts {
  .line {
    fill: none;
  }
}
