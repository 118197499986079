@import '../base/variables';
@import '../base/mixins';

$femaleColor: #ea608c;
$maleColor: #0d5589;

.project-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: calc(80vh - #{$mobileHeaderHeight} - #{$mobileFooterHeight});

  @media (min-width: $md) {
    height: calc(100vh - #{$headerHeight} - #{$footerHeight});
  }

  h1 {
    margin: 0.6em 0 0.2em 0;
    font-size: 1.6em;
    text-align: center;

    @media (min-width: $sm) {
      margin-top: 0.3em;
      font-size: 2.4em;
    }
  }

  .meta {
    max-width: 600px;
    margin-bottom: 0.8em;
    font-size: 0.85em;
    color: $grey;
    text-align: center;
  }

  .filters {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: center;

    .select-filter {
      margin-top: 0.75em;
    }

    .checkbox-filter {
      user-select: none;
      margin-top: 0.75em;

      input {
        display: none;
        padding: 0;
        margin: 0;
        height: initial;
        width: initial;
      }

      label {
        position: relative;
        cursor: pointer;

        &:before {
          margin: -2px 5px 0 0;
          padding: 8px;
          content: '';
          -webkit-appearance: none;
          background-color: transparent;
          border: 1px solid #bbb;
          border-radius: 4px;
          display: inline-block;
          position: relative;
          vertical-align: middle;
          cursor: pointer;
        }

        &:hover {
          &:before {
            border: 1px solid #666;
          }
        }
      }

      input:checked + label {
        &:before {
          border: 1px solid #666;
        }

        &:after {
          content: '✓';
          display: block;
          position: absolute;
          top: 1px;
          left: 4px;
          color: #666;
        }
      }

      &.female {
        label:hover {
          color: $femaleColor;
          &:before { border-color: $femaleColor; }
        }

        input:checked + label {
          color: $femaleColor;
          &:before { border-color: $femaleColor; }
          &:after { color: $femaleColor; }
        }
      }

      &.male {
        label:hover {
          color: $maleColor;
          &:before { border-color: $maleColor; }
        }

        input:checked + label {
          color: $maleColor;
          &:before { border-color: $maleColor; }
          &:after { color: $maleColor; }
        }
      }
    }

    .divider {
      color: $grey;
      margin: 0 1em;
    }
  }

  .container {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 800px;
    max-height: 800px;
    margin: 0 auto;

    @media (min-width: $sm + 1) {
      width: 80%;
      margin: 0;
    }

    svg.chart {
      position: relative;
      width: 100%;
      height: 100%;

      circle {
        stroke: #333;
      }

      text.axis-title {
        font-size: 0.75em;
        font-style: italic;
        text-anchor: middle;
      }
    }

    .tooltip {
      width: fit-content;
      max-width: 160px;
      opacity: 0;
      position: absolute;
      top: 20px;
      right: 20px;
      background-color: #ffffffaa;
      border: 1px solid #ddd;
      border-radius: 4px;
      padding: 4px 8px;
      line-height: 1.33;

      .Female { color: $femaleColor; }
      .Male { color: $maleColor; }
      .small { margin-top: 0.25em; font-size: 0.85em; }
    }

    #map {
      position: relative;
      width: 100%;
      height: calc(100% - 80px);
      max-height: 460px;
      border: 1px solid $lightGrey;

      .leaflet-control-attribution.leaflet-control {
        font-size: 7px;

        @media (min-width: $sm + 1) {
          font-size: 10px;
        }
      }

      .start-button {
        position: absolute;
        z-index: 1000;
        width: 100%;
        height: 100px;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background: rgba($white, 0.85);
        cursor: pointer;

        &:before {
          content: 'Click to start';
          position: absolute;
          z-index: 1;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
          color: $grey;
          font-family: 'Montserrat', sans-serif;
          font-size: 1.8em;
          white-space: nowrap;
        }
      }

      .marker {
        width: $marker-size !important;
        height: $marker-size !important;
        margin-left: -$marker-size / 2 !important;
        margin-top: -$marker-size / 2 !important;
        border-radius: $marker-size / 2;
        border: $border;
        background: rgba($accentColor, 0.85);
        cursor: default;

        &.animated {
          @include animation(#{$marker-animation});
        }
      }

      .tooltip {
        padding: 4px 12px;
        white-space: nowrap;
        font-size: 1em;
        color: $darkGrey;
        border: 1px solid $grey;
        transition: none !important;

        &:before {
          display: none;
        }
      }

      &:hover {
        .start-button:before {
          color: $darkGrey;
        }
      }
    }

    .controls-container {
      font-size: 2em;
      user-select: none;

      .line {
        position: relative;
        width: 80%;
        height: 40px;
        padding: 20px 0;
        margin: 0 auto;

        &:before {
          content: '';
          position: absolute;
          top: 20px;
          left: 0;
          width: calc(100% + 1px);
          height: 1px;
          background: $grey;
        }

        .point {
          position: absolute;
          top: 16px;
          width: 1px;
          height: 4px;
          background: $grey;

          &.zero {
            top: 20px;
            height: 5px;
            background: $darkGrey;
          }
        }

        .slider {
          position: absolute;
          top: 21px;
          left: 0;
          width: 0;
          height: 0;
          border-left: 6px solid rgba($darkGrey, 0.01);
          border-right: 6px solid rgba($darkGrey, 0.01);
          border-bottom: 12px solid $darkGrey;
          transform: translateX(-50%);
        }
      }

      .controls {
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        .button {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          margin-right: 0.5em;
          border-radius: 4px;
          border: 1px solid $lightGrey;
          cursor: pointer;

          &:after {
            content: '';
            width: 0;
            height: 0;
            margin-left: 2px;
            border-left: 12px solid $grey;
            border-bottom: 8px solid rgba($white, 0.1);
            border-top: 8px solid rgba($white, 0.1);
            color: $grey;
            font-size: 0.75em;
          }

          &:hover {
            border-color: $darkGrey;

            &:after {
              border-left-color: $darkGrey;
              color: $darkGrey;
            }
          }
        }

        .year {
          width: 80px;
          margin-right: 0.25em;
          padding: 0;
          text-align: right;
          border: none;
          border-bottom: 1px solid $lightGrey;
          border-radius: 0;
          outline: none;
          -moz-appearance: textfield;

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }

        .switch {
          width: 30px;
          text-align: center;
          font-size: 0.75em;
          line-height: 1.25em;
          color: $grey;
          border-bottom: 1px solid $lightGrey;

          &:hover {
            color: $darkGrey;
            border-bottom-color: $darkGrey;
            cursor: pointer;
          }
        }
      }

      &.playing {
        .controls {
          .button {
            &:after,
            &:before {
              content: '';
              width: 4px;
              height: 12px;
              background: $grey;
              border: none;
              margin: 0;
            }

            &:before {
              margin-right: 2px;
            }

            &:hover {
              border-color: $darkGrey;
              &:after,
              &:before {
                background: $darkGrey;
              }
            }
          }

          .year {
            border-bottom-color: $white;
            cursor: default;
            pointer-events: none;
          }

          .switch {
            border-bottom-color: $white;
            cursor: default;
            pointer-events: none;

            &:hover {
              color: $grey;
            }
          }
        }
      }

      &.ended {
        .button {
          &:after {
            content: '↻';
            margin: 1px 0 0 0;
            border: none;
            width: auto;
            height: auto;
          }
        }
      }
    }
  }
}
