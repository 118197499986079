@import '../base/variables';

.lj-wrapper {
  padding-top: 2em;

  form {
    display: flex;

    input {
      width: 120px;
      padding: 0.5em 0.6em;
      border: $border;
      border-right: none;
      border-radius: 4px 0 0 4px;
      outline: none;

      &:hover,
      &:focus,
      &:active {
        border-color: $black;
      }
    }

    button {
      padding: 0.5em 1em;
      background: $darkGrey;
      color: $white;
      border-radius: 0 4px 4px 0;
      border: none;
      outline: none;
      cursor: pointer;

      &:hover,
      &:focus,
      &:active {
        background: $darkGrey;
      }
    }
  }

  .lj-results {
    margin-top: 1.5em;

    div {
      height: 2em;
    }

    table {
      margin-top: 0.6em;
      border-spacing: 0;

      td {
        max-width: 300px;
        padding: 0.4em 0.8em 0.4em 0.4em;
        border-bottom: $border;
      }

      a {
        color: darken($accentColor, 10%);
        border-bottom: 1px solid rgba(darken($accentColor, 10%), 0.3);

        &:hover,
        &:focus,
        &:active {
          border-bottom-color: rgba(darken($accentColor, 10%), 0.8);
        }
      }

      thead tr td {
        border-bottom-width: 2px;
      }
    }
  }
}
