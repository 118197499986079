$images-path: '/static/images';

$headerHeight: 70px;
$mobileHeaderHeight: 45px;

$footerHeight: 60px;
$mobileFooterHeight: 40px;

$sm: 768px;
$md: 1024px;
$xxl: 1690px;

$mainFont: 'Montserrat', sans-serif;
$accentFont: 'Neucha', serif;

$white: #fff;
$lightGrey: #ddd;
$grey: #999;
$darkGrey: #333;
$black: #000;
$blue: #3772ff;

$textColor: $darkGrey;
$accentColor: $blue;

$border: 1px solid $darkGrey;

/* cities timeline page */
$marker-size: 16px;
$ease-spring: cubic-bezier(0.68, -0.55, 0.265, 1.55);

@-webkit-keyframes show-marker {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes show-marker {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes show-marker {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes scale-marker {
  0% {
    width: 0;
    height: 0;
    margin-left: 0;
    margin-top: 0;
    border-radius: 0;
  }
  100% {
    width: $marker-size;
    height: $marker-size;
    margin-left: -$marker-size / 2;
    margin-top: -$marker-size / 2;
    border-radius: $marker-size / 2;
  }
}
@-moz-keyframes scale-marker {
  0% {
    width: 0;
    height: 0;
    margin-left: 0;
    margin-top: 0;
    border-radius: 0;
  }
  100% {
    width: $marker-size;
    height: $marker-size;
    margin-left: -$marker-size / 2;
    margin-top: -$marker-size / 2;
    border-radius: $marker-size / 2;
  }
}
@keyframes scale-marker {
  0% {
    width: 0;
    height: 0;
    margin-left: 0;
    margin-top: 0;
    border-radius: 0;
  }
  100% {
    width: $marker-size;
    height: $marker-size;
    margin-left: -$marker-size / 2;
    margin-top: -$marker-size / 2;
    border-radius: $marker-size / 2;
  }
}

$marker-animation: '#{show-marker} 300ms #{$ease-spring},
                    #{scale-marker} 300ms #{$ease-spring}';

$marker-animation-completed: '#{show-marker} 0ms #{$ease-spring},
                              #{scale-marker} 0ms #{$ease-spring}';
