@import '../base/variables';

footer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  height: $mobileFooterHeight;

  @media (min-width: $sm) {
    height: $footerHeight;
  }

  a {
    font-size: 1.2em;
    border-bottom: 1px solid $lightGrey;

    &:hover {
      border-bottom-color: $grey;
    }
  }

  span {
    color: $grey;
  }
}
