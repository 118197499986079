@import 'node_modules/normalize.css/normalize';
@import 'variables';

* {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  font-family: $mainFont;
  font-size: 14px;
  color: $textColor;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  padding: 0 20px;
}

body > * {
  max-width: $xxl;
  margin: 0 auto;
}

main {
  position: relative;
  min-height: calc(100% - #{$mobileHeaderHeight} - #{$mobileFooterHeight});
}

a {
  color: $textColor;
  text-decoration: none;
}

@media (min-width: $sm) {
  body {
    padding: 0 30px;
  }

  main {
    min-height: calc(100% - #{$headerHeight} - #{$footerHeight});
  }
}
