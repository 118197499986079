@import '../base/variables';

header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: $mobileHeaderHeight;
  padding: 16px 0;

  .logo {
    font-family: $accentFont;
    font-size: 1.4em;
    color: $white;
    -webkit-text-stroke: 1px $darkGrey;
    text-decoration: none;
  }

  .socials {
    display: flex;
    align-items: center;

    a {
      margin-left: 12px;

      img {
        height: 20px;
        object-fit: contain;

        &:hover {
          filter: invert(35%) sepia(97%) saturate(2541%) hue-rotate(213deg)
            brightness(101%) contrast(102%);
        }
      }
    }
  }

  @media (min-width: $sm) {
    height: $headerHeight;
    padding: 20px 0;

    .logo {
      font-size: 2.4em;
    }

    .socials {
      a {
        margin-left: 20px;

        img {
          height: 32px;
        }
      }
    }
  }
}
