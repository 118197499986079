@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  src: url('/static/fonts/montserrat-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Neucha';
  font-weight: 400;
  src: url('/static/fonts/neucha-regular.ttf') format('truetype');
}
